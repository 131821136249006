
import {
  Emit,
  Prop,
  Component,
  Mixins,
  Watch,
  InjectReactive
} from 'vue-property-decorator';
import Vue from 'vue';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import ProfileDetailsBaseComponent from './ProfileDetailsBaseComponent.vue';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { getSectionStatus } from '@/util/getSectionStatus';
import { ESectionStatus } from '@/enums/ESectionStatus';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import generateScrollToCallback from '@/util/generateScrollToCallback';
import UpdateStickyComponent from '@/components/UpdateStickyComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { EExitReasonTypes } from '@/enums/EExitReasonTypes';

const sections = convertToKeyValueObj({
  BEFORE_OTS: 'Before OTS',
  DURING_OTS: 'During OTS',
  AFTER_OTS: 'After OTS'
});

class Model extends BaseCandidate {
  statusFunction: (fields: unknown[]) => ESectionStatus;

  get beforeOtsSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.officerTrainingSchoolWingscompletiondate,
      this.officerTrainingSchoolGtcmccompletiondate,
      this.officerTrainingSchoolPromotecompletiondate,
      this.officerTrainingSchoolSf600rosterdate
    ]);
  }

  get duringOtsSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.officerTrainingSchoolSchedulepostotscallcompletiondate,
      this.officerTrainingSchoolDegreeauditdate
    ]);
  }

  get afterOtsSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.officerTrainingSchoolConfirmo1rankdate,
      this.officerTrainingSchoolUpdatedutyinfodate,
      this.officerTrainingSchoolDeletedoedate,
      this.officerTrainingSchoolDeleteetsdate,
      this.officerTrainingSchoolDeleteetodate,
      this.officerTrainingSchoolAwardsdate,
      this.officerTrainingSchoolRscuptdate,
      this.officerTrainingSchoolAofromafpcdate,
      this.officerTrainingSchoolDissdate,
      this.officerTrainingSchoolCompcatdate,
      this.officerTrainingSchoolAf475completedate,
      this.officerTrainingSchoolPostcompletedate
    ]);
  }

  constructor(
    partial?: Partial<BaseCandidate>,
    statusFunction = getSectionStatus
  ) {
    super(partial);
    this.statusFunction = statusFunction;
  }
}

@Component<OtsPage>({
  components: {
    DateSelectorComponent,
    ProfileDetailsBaseComponent,
    UpdateStickyComponent
  }
})
export default class OtsPage extends Mixins(Vue, SnackbarMixin) {
  @Prop({ required: true })
  applicant!: Applicant;

  @InjectReactive('isUpdating')
  isUpdating!: boolean;

  model = new Model();

  created(): void {
    this.model = new Model(this.applicant);
  }
  yesNoOptions = yesNoOptions;
  sections = sections;

  exitReasonItems = getInputOptions(EExitReasonTypes);

  @Watch('model')
  @Watch('applicant')
  setSectionStatus(): void {
    const { BEFORE_OTS, DURING_OTS, AFTER_OTS } = this.sections;
    this.$store.dispatch('profileSideNavigationModule/setSections', [
      {
        header: 'OTS',
        entries: [
          {
            text: BEFORE_OTS.label,
            status: this.model.beforeOtsSectionStatus,
            onClick: generateScrollToCallback(BEFORE_OTS.key)
          },
          {
            text: DURING_OTS.label,
            status: this.model.duringOtsSectionStatus,
            onClick: generateScrollToCallback(DURING_OTS.key)
          },
          {
            text: AFTER_OTS.label,
            status: this.model.afterOtsSectionStatus,
            onClick: generateScrollToCallback(AFTER_OTS.key)
          }
        ]
      }
    ]);
  }

  @Emit('updateApplicant')
  updateApplicant(): Model {
    return this.model;
  }
}
