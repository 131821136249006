var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "profile-details-base-component",
    { attrs: { title: "Officer Training School" } },
    [
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.BEFORE_OTS.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.BEFORE_OTS.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("date-selector-component", {
            attrs: { label: "Wings" },
            model: {
              value: _vm.model.officerTrainingSchoolWingscompletiondate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolWingscompletiondate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolWingscompletiondate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "GTCC to Mission Critical" },
            model: {
              value: _vm.model.officerTrainingSchoolGtcmccompletiondate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolGtcmccompletiondate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolGtcmccompletiondate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Promote" },
            model: {
              value: _vm.model.officerTrainingSchoolPromotecompletiondate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolPromotecompletiondate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolPromotecompletiondate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "SF600 Roster" },
            model: {
              value: _vm.model.officerTrainingSchoolSf600rosterdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolSf600rosterdate", $$v)
              },
              expression: "model.officerTrainingSchoolSf600rosterdate",
            },
          }),
        ],
        1
      ),
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.DURING_OTS.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.DURING_OTS.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("date-selector-component", {
            attrs: { label: "Schedule Post OTS Call" },
            model: {
              value:
                _vm.model
                  .officerTrainingSchoolSchedulepostotscallcompletiondate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolSchedulepostotscallcompletiondate",
                  $$v
                )
              },
              expression:
                "model.officerTrainingSchoolSchedulepostotscallcompletiondate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Degree Audit" },
            model: {
              value: _vm.model.officerTrainingSchoolDegreeauditdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolDegreeauditdate", $$v)
              },
              expression: "model.officerTrainingSchoolDegreeauditdate",
            },
          }),
        ],
        1
      ),
      _c(
        "h3",
        {
          staticClass: "section-header",
          attrs: { id: _vm.sections.AFTER_OTS.key },
        },
        [_vm._v(" " + _vm._s(_vm.sections.AFTER_OTS.label) + " ")]
      ),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("date-selector-component", {
            attrs: { label: "Confirm O-1 Rank" },
            model: {
              value: _vm.model.officerTrainingSchoolConfirmo1rankdate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolConfirmo1rankdate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolConfirmo1rankdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Update Duty Info" },
            model: {
              value: _vm.model.officerTrainingSchoolUpdatedutyinfodate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolUpdatedutyinfodate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolUpdatedutyinfodate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Delete DOE" },
            model: {
              value: _vm.model.officerTrainingSchoolDeletedoedate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolDeletedoedate", $$v)
              },
              expression: "model.officerTrainingSchoolDeletedoedate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Delete ETS" },
            model: {
              value: _vm.model.officerTrainingSchoolDeleteetsdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolDeleteetsdate", $$v)
              },
              expression: "model.officerTrainingSchoolDeleteetsdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Delete ETO" },
            model: {
              value: _vm.model.officerTrainingSchoolDeleteetodate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolDeleteetodate", $$v)
              },
              expression: "model.officerTrainingSchoolDeleteetodate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Awards - ND/TR/TS" },
            model: {
              value: _vm.model.officerTrainingSchoolAwardsdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolAwardsdate", $$v)
              },
              expression: "model.officerTrainingSchoolAwardsdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "RSC (UPT)" },
            model: {
              value: _vm.model.officerTrainingSchoolRscuptdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolRscuptdate", $$v)
              },
              expression: "model.officerTrainingSchoolRscuptdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "AO from ARPC" },
            model: {
              value: _vm.model.officerTrainingSchoolAofromafpcdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolAofromafpcdate", $$v)
              },
              expression: "model.officerTrainingSchoolAofromafpcdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "DISS" },
            model: {
              value: _vm.model.officerTrainingSchoolDissdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolDissdate", $$v)
              },
              expression: "model.officerTrainingSchoolDissdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "COMPCAT" },
            model: {
              value: _vm.model.officerTrainingSchoolCompcatdate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "officerTrainingSchoolCompcatdate", $$v)
              },
              expression: "model.officerTrainingSchoolCompcatdate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "AF475" },
            model: {
              value: _vm.model.officerTrainingSchoolAf475completedate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolAf475completedate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolAf475completedate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Post Complete" },
            model: {
              value: _vm.model.officerTrainingSchoolPostcompletedate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolPostcompletedate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolPostcompletedate",
            },
          }),
          _c("date-selector-component", {
            attrs: { label: "Oath of Office" },
            model: {
              value: _vm.model.officerTrainingSchoolOathofofficedate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "officerTrainingSchoolOathofofficedate",
                  $$v
                )
              },
              expression: "model.officerTrainingSchoolOathofofficedate",
            },
          }),
        ],
        1
      ),
      _c("update-sticky-component", {
        attrs: { isUpdating: _vm.isUpdating },
        on: { click: _vm.updateApplicant },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }